import logo from '../logo.png';
import './fourohfour.css';

const FourOFour = () => {
    return (
        <div className='empty-space'>
            <h1>404</h1>
            <img src={logo} alt="logo" />
            <h3>Page not found</h3>
        </div>
        
    );
    }

export default FourOFour;