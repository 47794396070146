import React from 'react';
import './Rules.css';

const Rules = () => {
    return (
        <div className='page-container'>
        <section className="section">
            <h2>Server Rules</h2>
            <ul>
                <li>Be friendly to other players. We won't tolerate any toxic behaviour, and if you can't be civil, you'll need to find a different server to play on.</li>
                <li>Cheating is not allowed. If you're caught exploiting bugs or using cheats, you'll be banned from the server network.<br/>
                    This also includes the exploitation of softcore features.</li>
                    <li>Don't associate with cheaters or exploiters. If we find out that you're withholding information or helping someone cheat, you'll also be banned.</li>
                    <li>We welcome both experienced and new players. However, we ask that experienced players don't dominate new players and ruin their experience. Our server is meant to be enjoyable for everyone.</li>
                    <li>Respect the server's staff and their decisions. The staff are there to help maintain a fair and enjoyable playing experience for everyone, so please follow their instructions and respect their authority.</li>
                    <li>Do not use offensive language or hate speech. This includes racial, sexist, or homophobic slurs, as well as any other derogatory language/signs. Please also avoid discussions related to politics/world events. We want to maintain a welcoming and inclusive environment for all players.</li>
                    <li>Do not spam the chat. This includes excessive use of caps, emojis, or repeated messages. If you have a question or need help, please ask once and wait for a response.</li>
                    <li>Do not advertise other servers or websites. We want to keep the focus on our server and community, so please refrain from promoting other servers or websites in the chat.</li>
                    <li>Do not impersonate staff members. This includes using similar names or pretending to have authority on the server. If you have an issue, please contact a real staff member for assistance.</li>
                    <li>Accounts with more than one VAC/Game/EAC bans will be automatically kicked</li>
                    <li>Accounts with VAC bans under 1 year old will be automatically kicked</li>
            </ul>
        </section>
        </div>
    );
}

export default Rules;
