

const ProgressBar = ({ progress }) => {
    return (

<div className="progress-bar">

            <style>
                {`
                .progress-bar {
                    width: 100%;
                    height: 15px;
                    border: 1px solid #333;
                    border-radius: 5px;
                    overflow: hidden;
                }
                .progress {
                    height: 100%;
                    background-color: #ff8110;
                    transition: width 0.5s;
                }

                .progress-value {
                    font-size: 12px;
                    text-align: right;
                    padding-right: 5px;
                    color: #fff;
                }
                `}

            </style>

        <div className="progress" style={{ width: `${progress}%` }}>
            <div className="progress-value">{progress}%</div>
        </div>

        </div>
    );
    }

export default ProgressBar;