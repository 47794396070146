import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../logo.png';
import { AuthContext } from '../AuthContext';
import axios from 'axios';

const Navbar = () => {
    const { user, logout, token } = useContext(AuthContext);
    const [profile, setProfile] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showLeaderboardDropdown, setShowLeaderboardDropdown] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (token) {
                try {
                    const response = await axios.get('https://r4n.cyphersoftware.dev/api/user', {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setProfile(response.data);
                    // Fetch subscriptions
                    const subsResponse = await axios.get('https://r4n.cyphersoftware.dev/api/subscriptions', {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setSubscriptions(subsResponse.data);
                } catch (error) {
                    console.error('Failed to fetch profile:', error);
                }
            }
        };

        fetchProfile();
    }, [token]);

    const handleDropdownToggle = () => {
        setShowDropdown(!showDropdown);
    };

    const handleCancelSubscription = async (subscriptionId) => {
        try {
            await axios.post(
                `https://r4n.cyphersoftware.dev/api/cancel-subscription`,
                { subscriptionId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setSubscriptions(subscriptions.filter(sub => sub.id !== subscriptionId));
        } catch (error) {
            console.error('Failed to cancel subscription:', error);
        }
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <img src={logo} alt="logo" />
            </div>
            <div className="navbar-links">
                <Link to="/">Home</Link>
                <Link to="/about">About</Link>
                
                <div className="navbar-leaderboard" onClick={() => setShowLeaderboardDropdown(!showLeaderboardDropdown)}>
                    Leaderboard
                    {showLeaderboardDropdown && (
                        <div className="navbar-leaderboard-dropdown">
                            <Link to="/leaderboard/main">- EU Main -</Link>
                            <Link to="/leaderboard/trio">- EU Trio -</Link>
                            <Link to="/leaderboard/duo">- EU Duo -</Link>
                            <Link to="/leaderboard/solo">- EU Solo -</Link>
                            <Link to="/leaderboard/pve">- EU PVE -</Link>
                        </div>
                    )}
                </div>
                <Link to="/staff">Staff</Link>
                <Link to="/rules">Rules</Link>
                <Link to="/FAQ">FAQ</Link>
                <a href='https://store.4noobs.co/products?tag=products' target='_blank' rel='noreferrer noopener'>Store</a>
            </div>
            <div className="navbar-auth">
                {profile ? (
                    <>
                        <div className="navbar-profile" onClick={handleDropdownToggle}>
                            <img src={profile.avatar} alt={profile.display_name} className="navbar-avatar" />
                            <span>{profile.display_name}</span>
                        </div>
                        {showDropdown && (
                            <div className="navbar-dropdown">
                                <button onClick={logout} className="navbar-logout">Logout</button>
                                <div className="navbar-subscriptions">
                                    <h3>Manage Subscriptions</h3>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Subscription</th>
                                                <th>Expiry Date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subscriptions.map(sub => (
                                                <tr key={sub.id}>
                                                    <td>{sub.name}</td>
                                                    <td>{new Date(sub.expiry_date * 1000).toLocaleDateString()}</td>
                                                    <td>
                                                        <button onClick={() => handleCancelSubscription(sub.id)}>Cancel</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                        <button onClick={() => window.location.href = 'https://r4n.cyphersoftware.dev/auth/steam'} className="navbar-login">
                        Login with Steam
                    </button>
                )}
            </div>
        </nav>
    );
};

export default Navbar;
