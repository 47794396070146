import { useEffect, useState } from 'react';
import './leaderboard.css';

// Resource Images
import sulfur from '../../Assets/sulfur.png';
import stone from '../../Assets/stones.png';
import wood from '../../Assets/wood.png';
import metal from '../../Assets/metal.png';
import hqm from '../../Assets/hqm-ore.png';

import bullet from '../../Assets/bullet.png';
import arrow from '../../Assets/arrow.png';
import c4 from '../../Assets/c4.png';
import rocket from '../../Assets/rocket.png'



import ProgressBar from '../../components/ProgressBar';

const LeaderBoardSolo = () => {
    const [leaderboardData, setLeaderboardData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(`https://api.rankeval.gg/api/getleaderboards?ServerFilter=64751e611e6fd95d2b8b4a1c&Type=Full&page=1&perPage=50&sortBy=Rank`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Leaderboard data:', data);
                setLeaderboardData(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching leaderboard data:', error);
                setLoading(false);
            });
    }, []);


    function getRankingColor(ranking) {
        if (ranking >= 0 && ranking <= 1) {
            return 'gold';
        } else if (ranking >= 2 && ranking <= 2) {
            return 'silver';
        } else if (ranking >= 3 && ranking <= 3) {
            return 'bronze';
        } else {
            return 'white';
        }
    }

    // Function to turn seconds into a more readable format
    function formatTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${hours}h ${minutes}m`;
    }

    // Function to turn large numbers into a more readable format
    function formatNumber(number) {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + 'K';
        } else {
            return number;
        }
    }

    return (
        <div className='page-container'>
            <br />
            <h1>Rust4Noobs - EU Solo</h1>
            {loading && <p>Loading...</p>}
            {leaderboardData && (
                <table>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Player</th>
                            <th>PVP Stats</th>
                            <th>PVE Stats</th>
                            <th>Gather Stats</th>
                            <th>Ballistic Stats</th>
                            <th>Ratings</th>
                            <th>Time Played</th>
                            <th>Rating Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        {leaderboardData.leaderboard.map(player => {

                            // Rank is the index of the player in the array, plus 1
                            const rank = leaderboardData.leaderboard.indexOf(player) + 1;
                            return (
                                <tr key={player.SteamID}>
                                    <td className={`rank-${getRankingColor(rank)}`}>{rank}</td>
                                    <td className='player-tbl'>
                                        <a href={`https://steamcommunity.com/profiles/${player.SteamID}`} target='_blank' rel='noreferrer noopener'>
                                            <img src={player.User.Avatar.avatarFull} className='avatar' alt={`${player.Name}'s avatar`} />
                                        </a>
                                        <h5>{player.Name}</h5>
                                    </td>
                                    <td>
                                        <div>Kills: {player.PVPKills}</div>
                                        <div>Deaths: {player.Deaths}</div>
                                        <div>K/D: {player.KDR}</div>
                                    </td>
                                    <td>
                                        <div>PVE Kills: {player.PVEKills}</div>
                                        <div>NPC Kills: {player.NPCKills}</div>
                                        <div>APC Hits: {player.APCHits}</div>
                                        <div>APC Kills: {player.APCKills}</div>
                                        <div>Heli Hits: {player.HeliHits}</div>
                                        <div>Heli Kills: {player.HeliKills}</div>
                                    </td>
                                    <td className='gather'>
                                        <div><img className='gather-img' src={wood} /> {formatNumber(player.Wood)}</div>
                                        <div><img className='gather-img' src={stone} /> {formatNumber(player.Stone)}</div>
                                        <div><img className='gather-img' src={metal} /> {formatNumber(player.Metal)}</div>
                                        <div><img className='gather-img' src={sulfur} /> {formatNumber(player.Sulfur)}</div>
                                        <div><img className='gather-img' src={hqm} /> {formatNumber(player.HQM)}</div>
                                    </td>
                                    <td>
                                        <div className='bal-item'><img src={bullet} />{player.BulletsFired}</div>
                                        <div className='bal-item'><img src={arrow} />{player.ArrowsFired}</div>
                                        <div className='bal-item'><img src={c4} />{player.ExplosivesThrown}</div>
                                        <div className='bal-item'><img src={rocket} />{player.RocketsLaunched}</div>

                                    </td>
                                    <td className='rating-container'> {/* Applied rating-container class here */}
                                        <div className='rating-item'>
                                            <h6>PVP</h6>
                                            <ProgressBar progress={player.Rankings.PVPPerf} />
                                        </div>
                                        <div className='rating-item'>
                                            <h6>PVE</h6>
                                            <ProgressBar progress={player.Rankings.PVEPerf} />
                                        </div>
                                        <div className='rating-item'>
                                            <h6>Gather</h6>
                                            <ProgressBar progress={player.Rankings.GatherPerf} />
                                        </div>
                                        <div className='rating-item'>
                                            <h6>Ballistic</h6>
                                            <ProgressBar progress={player.Rankings.BallisticsPerf} />
                                        </div>
                                    </td>
                                    <td>{formatTime(player.TimePlayed)}</td>
                                    <td>{player.Rankings.Rating}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default LeaderBoardSolo;
