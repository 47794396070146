import './FAQ.css';
const FAQ = () => {
    return (
        <div className='page-container'>
            <div className="faq-article">
                <div className="faq-question">
                    <h3>What, and when is wipe?</h3>
                </div>
                <div className="faq-answer">
                    <p>Every rust server in the game is required to wipe on the first Thursday of the new month at 7pm GMT, to allow facepunch to release new game content and updates. It also helps to keep gameplay fresh, and exciting for players. Some rust servers have daily/weekly/bi-weekly wipes too, this is down to the independent server. </p>
                </div>
            </div>
            <div className="faq-article">
                <div className="faq-question">
                    <h3>What is Softcore?</h3>
                </div>
                <div className="faq-answer">
                    <p>Softcore is an official gamemode introduced by facepunch in 2021, to give some players the option to play a more merciful version of the game.<br /><strong><br />Features:</strong><br /><br />Respawn points at Bandit camp/Outpost<br />50% of loot is sent to a reclaim terminal at Outpost or bandit camp (this does not include armour/what you are holding, and is </p>
                </div>
            </div>
            <div className="faq-article">
                <div className="faq-question">
                    <h3>What is the team limit?</h3>
                </div>
                <div className="faq-answer">
                    <p>Every one of our servers has the team limit in the description. Press "ESC" and view "session" to view this.</p>
                </div>
            </div>
            <div className="faq-article">
                <div className="faq-question">
                    <h3>Where are the servers located?</h3>
                </div>
                <div className="faq-answer">
                    <p>Our EU fleet is located in the UK. Our Oceania machine is located in Sydney, Australia. And our US machine is based in Dallas, Texas.</p>
                </div>
            </div>
            <div className="faq-article">
                <div className="faq-question">
                    <h3>When will the new map be for wipe?</h3>
                </div>
                <div className="faq-answer">
                    <p>We always publish our new server maps on our <a href='https://discord.gg/7Zb3rjz'>Discord</a> in the #wipe-feed channel.</p>
                </div>
            </div>
        </div>
    );
}

export default FAQ;